import {reactive, toRefs} from "vue";
import service from "@/utils/axios";
import router from "@/router";
import {onPageLoadingEnd, onPageLoadingStart} from "@/utils/DLoading";

export interface ICourseInfo {
  beginDate: string;
  classId: string;
  className: string;
  classRoomName: string;
  courseIndex: number;
  currentDate: string;
  endTime: string;
  haveEnd: boolean;
  loadProgress: number;
  scheduleId: string;
  startTime: string;
  studentCount: number;
  totalProgress: number;
  week: number;
  weekStr: string;
  isTodayCourse: boolean;
}

export interface IMyTimetableState {
  showTitle: boolean;
  myTimetableList: Array<ICourseInfo>;      // 今日课程
  otherTimetableList: Array<ICourseInfo>;   // 其他课程
}


export const myTimetableBill = () => {
  let stateRef = reactive(<IMyTimetableState>{
    showTitle: true,
    myTimetableList: [],
    otherTimetableList: [],
  });

  let refData = toRefs(stateRef)

  let onScrollMethod = (e: any) => {
    stateRef.showTitle = e.target.scrollTop <= 300;
  }


  let init = async () => {
    await getCourse();
  }

  let getCourse = async () => {
    onPageLoadingStart()
    stateRef.myTimetableList = [];
    stateRef.otherTimetableList = [];
    const r: Array<ICourseInfo> = await service.get('/dwart/an_teaching/classCourse/queryCourseByUid');
    r.forEach(v => {
      if (v?.isTodayCourse) {
        stateRef.myTimetableList.push(v);
      } else {
        stateRef.otherTimetableList.push(v)
      }
    })
    onPageLoadingEnd()
  }

  let onGotoTopicCheck = async (item: ICourseInfo) => {
    await router.push({
      name: 'ClassTime',
      query: {
        classId: item.classId,
        className: item.className,
      }
    })
    await service.post('/dwart/an_teaching/loginRecord/v1/inClassroom', {
      "mac": localStorage.getItem('macAd'),
      "version": "2.3",
      "type": 1,
      classroom:item.classRoomName,
      dealCode:2,
    })
  }


  return {
    init,
    refData,
    onScrollMethod,
    onGotoTopicCheck,
  }
}
