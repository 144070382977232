import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "timetable-card2"
}
const _hoisted_2 = { class: "timetable-card2__classname" }
const _hoisted_3 = { class: "timetable-card2__classtimes" }
const _hoisted_4 = { class: "timetable-card2__line3" }
const _hoisted_5 = {
  key: 1,
  class: "timetable-card1"
}
const _hoisted_6 = { class: "timetable-card__content" }
const _hoisted_7 = { class: "timetable-card__content--line2" }
const _hoisted_8 = { class: "timetable-card__content--classname" }
const _hoisted_9 = { class: "timetable-card__content--line3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HourglassOutlined = _resolveComponent("HourglassOutlined")!
  const _component_UserOutlined = _resolveComponent("UserOutlined")!

  return (_openBlock(), _createElementBlock("div", {
    class: "timetable-card",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, [
    (_ctx.isOther)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.className), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.classTime), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createVNode(_component_HourglassOutlined, { style: {"margin-right":"10px","color":"#F17C26"} }),
              _createTextVNode(" " + _toDisplayString(_ctx.classProgress), 1)
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_UserOutlined, { style: {"margin-right":"10px","color":"#F17C26"} }),
              _createTextVNode(" " + _toDisplayString(_ctx.studentNum), 1)
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"timetable-card__content--dot1\" data-v-fdce312a></div><div class=\"timetable-card__content--dot2\" data-v-fdce312a></div><div class=\"timetable-card__content--dot3\" data-v-fdce312a></div><div class=\"timetable-card__content--dot4\" data-v-fdce312a></div><div class=\"timetable-card__content--line1\" data-v-fdce312a></div>", 5)),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.className), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.classTime), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", null, [
                _createVNode(_component_HourglassOutlined, { style: {"margin-right":"10px"} }),
                _createTextVNode(" " + _toDisplayString(_ctx.classProgress), 1)
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_UserOutlined, { style: {"margin-right":"10px"} }),
                _createTextVNode(" " + _toDisplayString(_ctx.studentNum), 1)
              ])
            ])
          ])
        ]))
  ]))
}