
import GoBackIcon from "@/components/system/GoBackIcon.vue";
import TeacherInformation from "@/components/system/TeacherInformation.vue";
import LogoutIcon from "@/components/system/LogoutIcon.vue";
import TimetableTitle from "@/components/timetable/TimetableTitle.vue";
import {defineComponent, onMounted, reactive, toRefs} from "vue";
import TimetableDisplay from "@/components/timetable/TimetableDisplay.vue";
import TimetableCard from "@/components/timetable/TimetableCard.vue";
import {myTimetableBill} from "@/views/timetable/myTimetable/MyTimetable/MyTimetable";
import EmptyNoLesson from "@/components/timetable/EmptyNoLesson.vue";

export default defineComponent({
  name: "MyTimetable",
  components: {
    EmptyNoLesson,
    TimetableCard,
    TimetableDisplay,
    TimetableTitle,
    LogoutIcon,
    TeacherInformation,
    GoBackIcon
  },
  setup() {

    const {
      refData,
      onScrollMethod,
      onGotoTopicCheck,
      init,
    } = myTimetableBill();


    onMounted(() => {
      init();
    })


    return {
      ...refData,
      onScrollMethod,
      onGotoTopicCheck,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
