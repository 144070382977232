import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-timetable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_go_back_icon = _resolveComponent("go-back-icon")!
  const _component_timetable_title = _resolveComponent("timetable-title")!
  const _component_logout_icon = _resolveComponent("logout-icon")!
  const _component_empty_no_lesson = _resolveComponent("empty-no-lesson")!
  const _component_timetable_card = _resolveComponent("timetable-card")!
  const _component_timetable_display = _resolveComponent("timetable-display")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      "enter-active-class": "animate__animated animate__faster animate__fadeInDown",
      "leave-active-class": "animate__animated animate__faster animate__fadeOutUp"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(_component_go_back_icon),
          _createVNode(_component_timetable_title, null, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("我的课表")
            ])),
            _: 1
          }),
          _createVNode(_component_logout_icon)
        ], 512), [
          [_vShow, _ctx.showTitle]
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", {
      class: "my-timetable__body scroll-bar-hidden",
      onScrollPassive: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onScrollMethod && _ctx.onScrollMethod(...args)))
    }, [
      _createVNode(_component_timetable_display, { style: {"margin-top":"15px"} }, {
        default: _withCtx(() => [
          (_ctx.myTimetableList.length === 0)
            ? (_openBlock(), _createBlock(_component_empty_no_lesson, { key: 0 }))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.myTimetableList, (i) => {
                return (_openBlock(), _createBlock(_component_timetable_card, {
                  key: i.classId,
                  "class-name": i.className,
                  "class-time": `${i.weekStr} ${i.startTime}-${i.endTime}`,
                  "class-progress": `${i.loadProgress}/${i.totalProgress}课次`,
                  "student-num": `${i.studentCount}人`,
                  onClick: ($event: any) => (_ctx.onGotoTopicCheck(i)),
                  "is-other": ""
                }, null, 8, ["class-name", "class-time", "class-progress", "student-num", "onClick"]))
              }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_timetable_display, {
        title: "其他课程",
        style: {"margin-top":"30px"}
      }, {
        default: _withCtx(() => [
          (_ctx.otherTimetableList.length === 0)
            ? (_openBlock(), _createBlock(_component_empty_no_lesson, { key: 0 }))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherTimetableList, (i) => {
            return (_openBlock(), _createBlock(_component_timetable_card, {
              key: i.classId,
              "class-name": i.className,
              "class-time": `${i.weekStr} ${i.startTime}-${i.endTime}`,
              "class-progress": `${i.loadProgress}/${i.totalProgress}课次`,
              "student-num": `${i.studentCount}人`,
              "is-other": "",
              onClick: ($event: any) => (_ctx.onGotoTopicCheck(i))
            }, null, 8, ["class-name", "class-time", "class-progress", "student-num", "onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ], 32)
  ]))
}