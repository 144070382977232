import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "empty-no-lesson" }
const _hoisted_2 = { class: "empty-no__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_empty = _resolveComponent("a-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_empty, {
      image: _ctx.imgPath,
      "image-style": {
              height: `${_ctx.height}px`,
            }
    }, {
      image: _withCtx(() => _cache[0] || (_cache[0] = [])),
      description: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _cache[1] || (_cache[1] = _createTextVNode(" 暂无课程哦~ "))
          ], true)
        ])
      ]),
      _: 3
    }, 8, ["image", "image-style"])
  ]))
}